module.exports = new Promise((resolve) => {
    const module = {
        get: (request) => window.host.get(request),
        init: (arg) => {
            try {
                return window.host.init(arg);
            }
            catch (e) {
                console.error('Host has already been loaded');
            }
        },
    };
    resolve(module);
});